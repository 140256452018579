import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Link } from "gatsby"

import ArrowDown from "images/arrow-down.inline.svg"
import ArrowRight from "images/arrow-right.inline.svg"
import BtnDownload from "components/BtnDownload"
import LogoUvasc from "images/logo-uvasc.inline.svg"

const HomeFirstSection = () => {
  const { t } = useTranslation()

  return (
    <section className="home-first-section">
      <div className="home-first-section-content">
        <div className="container">
          <div className="home-first-section-left">
            <h1 className="home-title bold-40">
              {t("Lymphedema volume monitoring and calculation solution")}
            </h1>
            <Link to="/offers/" className="btn-primary regular-20">
              {t("I want to try")} <ArrowRight height="15" className="ms-1" />
            </Link>
          </div>
          <div className="home-first-section-right">
            <div className="home-first-section-right-mockup">
              <StaticImage
                alt={t("Imac with UVASC Cloud App")}
                layout="constrained"
                placeholder="blurred"
                src="../../images/macbook-mockup.png"
                width={380}
              />
              <StaticImage
                alt={t("Tablet with a scan of a leg")}
                className="mockup-tablet"
                layout="constrained"
                placeholder="blurred"
                src="../../images/tablet-scan-foot.png"
              />
            </div>
            <div className="home-first-section-right-download">
              <BtnDownload />
              <div className="download-uvasc-logo-small ms-1">
                <LogoUvasc />
              </div>
            </div>
          </div>
        </div>
        <button
          className="home-next-section-button"
          onClick={() => scrollTo("#home-third-section")}
        >
          <ArrowDown height="8" />
          <ArrowDown height="8" />
        </button>
      </div>
    </section>
  )
}

export default HomeFirstSection
