import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

import ArrowRight from "images/arrow-right.inline.svg"

const HomeFourthSection = () => {
  const { t } = useTranslation()

  return (
    <section className="section-padding">
      <div className="container">
        <h2 className="bold-60 home-fourth-section-title">
          {t("Designed for and by professionals")}
        </h2>
        <div className="home-fourth-section-1">
          <div className="home-fourth-section-1-left">
            <h3 className="bold-40 home-section-subtitle">
              {t("Validated by care professionals")}
            </h3>
            <ul className="light-24 home-section-list">
              <li>
                <strong className="bold">{t("Caregivers")}</strong>
                {" : "}
                {t("physiotherapist, doctor, surgeon, nurse")}
              </li>
              <li>
                <strong className="bold">
                  {t("Health care institutions")}
                </strong>{" "}
                {t("and")} <strong className="bold">{t("clinic")}</strong>
              </li>
              <li>
                <strong className="bold">{t("Clinical research tool")}</strong>{" "}
                {t("for monitoring therapeutic effectiveness")}
              </li>
              <li>
                <strong className="bold">{t("Pharmacist")}</strong>{" "}
                {t("to facilitate the taking of measurements")}
              </li>
            </ul>
          </div>
          <div className="home-fourth-section-1-right"></div>
        </div>
        <div className="home-fourth-section-2">
          <div className="home-fourth-section-2-left">
            <StaticImage
              alt={t("Ipad with UVASC tablet App")}
              layout="fixed"
              placeholder="blurred"
              src="../../images/tablet-behind-infrontof.png"
            />
          </div>
          <div className="home-fourth-section-2-right">
            <h3 className="bold-40 home-section-subtitle">
              {t("Innovative technology, reliable measurements")}
            </h3>
            <ul className="light-24 home-section-list">
              <li>
                <strong className="bold">{t("Plug & Play")}</strong>
                {" : "}
                {t("an application and a sensor to install on your tablet")}
              </li>
              <li>
                <strong className="bold">{t("Volume measurement tool")}</strong>
                {" : "}
                {t(
                  "simple and fast. Advanced imaging and improved volumetric tracking"
                )}
              </li>
              <li>
                <strong className="bold">{t("Advanced imaging")}</strong>{" "}
                {t("and remote monitoring")}
              </li>
              <li>
                <strong className="bold">
                  {t("Reliable and reproducible")}
                </strong>
              </li>
            </ul>
            <button className="btn-outline regular-20">
              {t("I want to try")} <ArrowRight height="15" className="ms-1" />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeFourthSection
