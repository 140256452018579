import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

import ArrowRight from "images/arrow-right.inline.svg"
import Cible from "images/cible.inline.svg"
import Clock from "images/clock.inline.svg"
import ThumbsUp from "images/main-haut-pouce.inline.svg"

const HomeThirdSection = () => {
  const { t } = useTranslation()

  return (
    <section className="home-third-section section-padding">
      <div className="container">
        <h2
          id="home-third-section"
          className="bold-60 home-third-section-title"
        >
          {t("Secure, user-friendly and connected")}
        </h2>
        <StaticImage
          alt={t("Imac with UVASC Cloud App")}
          layout="constrained"
          objectPosition="50% 50%"
          placeholder="blurred"
          src="../../images/tablet-ecran-uvasc-2.png"
        />
        <div className="home-third-section-content">
          <div>
            <p className="bold-20">{t("Easy to use interface")}</p>
            <ThumbsUp height="93" />
          </div>
          <div>
            <p className="bold-20">{t("10x faster acquisition")}</p>
            <Clock height="93" />
          </div>
          <div>
            <p className="bold-20">{t("Validated accuracy")}</p>
            <Cible height="93" />
          </div>
        </div>
        <button className="btn-primary regular-20">
          {t("Read more +")} <ArrowRight height="15" className="ms-1" />
        </button>
      </div>
    </section>
  )
}

export default HomeThirdSection
