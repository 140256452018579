import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import "assets/sass/carousel.scss"
import ArrowLeft from "images/arrow-left.inline.svg"
import ArrowRight from "images/arrow-right.inline.svg"

const Carousel = ({ children, setStep, step }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [maxStep, setMaxStep] = useState()

  useEffect(() => {
    setMaxStep(children.length)
  }, [])

  useEffect(() => {
    if (step) {
      setCurrentStep(step)
    }
  }, [step])

  const handlePrevStep = () => {
    let newStep
    if (currentStep === 1) {
      newStep = maxStep
    } else {
      newStep = currentStep - 1
    }
    handleSetStep(newStep)
  }

  const handleNextStep = () => {
    let newStep
    if (currentStep === maxStep) {
      newStep = 1
    } else {
      newStep = currentStep + 1
    }
    handleSetStep(newStep)
  }

  const handleSetStep = newStep => {
    setCurrentStep(newStep)
    setStep(newStep)
  }
  return (
    <div className="carousel-container">
      <button className="btn-arrow" onClick={handlePrevStep}>
        <ArrowLeft className="arrow" height="15" />
      </button>
      <div className="carousel-content">
        {children.map((child, index) => (
          <div
            id={`carousel-step-${index + 1}`}
            key={index}
            className={
              currentStep === index + 1
                ? "carousel-step active"
                : "carousel-step"
            }
          >
            {child}
          </div>
        ))}
      </div>
      <button className="btn-arrow" onClick={handleNextStep}>
        <ArrowRight className="arrow" height="15" />
      </button>
    </div>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  setStep: PropTypes.func,
  step: PropTypes.number,
}

Carousel.defaultProps = {
  setStep: () => {
    // Empty function
  },
  step: 1,
}

export default Carousel
