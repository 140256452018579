import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

const HomeSixthSection = () => {
  const { t } = useTranslation()

  return (
    <section className="home-sixth-section section-padding">
      <div className="container">
        <h2 className="bold-60 home-fourth-section-title">
          {t("Advanced volume imaging")}.<br />
          {t("In the service of vascular health!")}
        </h2>
        <div className="home-fourth-section-1">
          <div className="home-fourth-section-1-left">
            <h3 className="bold-40 home-section-subtitle">
              {t("Simplicity without compromise")}
            </h3>
            <ul className="light-24 home-section-list">
              <li>
                <strong className="bold">{t("The UVASC application")}</strong>{" "}
                {t(
                  "uses the latest technology to quickly and accurately generate the volume of a limb on the tablet"
                )}
              </li>
              <li>
                <strong className="bold">
                  {t("2D and 3D image acquisition")}
                </strong>{" "}
                {t("in high definition for reliable expert analysis")}
              </li>
              <li>
                <strong className="bold">
                  {t("Designed to work on an iPad")}
                </strong>{" "}
                {t(
                  "of the latest generation using advanced integrated optics and structured infrared light from a 3D sensor with millimetre precision"
                )}
              </li>
            </ul>
          </div>
          <div className="home-fourth-section-1-right">
            <StaticImage
              alt={t("Imac with UVASC Cloud App")}
              layout="constrained"
              placeholder="blurred"
              src="../../images/macbook-mockup.png"
              width={460}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeSixthSection
