import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import "assets/sass/home.scss"
import HomeFifthSection from "components/Home/HomeFifthSection"
import HomeFirstSection from "components/Home/HomeFirstSection"
import HomeFourthSection from "components/Home/HomeFourthSection"
import HomeSecondSection from "components/Home/HomeSecondSection"
import HomeSixthSection from "components/Home/HomeSixthSection"
import HomeThirdSection from "components/Home/HomeThirdSection"

const HomePage = () => (
  <Layout>
    <Seo title="Accueil" />
    <HomeFirstSection />
    <HomeSecondSection />
    <HomeThirdSection />
    <HomeFourthSection />
    <HomeFifthSection />
    <HomeSixthSection />
  </Layout>
)

export default HomePage
