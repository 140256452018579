import React from "react"
import { useTranslation } from "react-i18next"

const HomeSecondSection = () => {
  const { t } = useTranslation()

  return (
    <section className="home-second-section section-padding">
      <div className="container">
        <h2 className="bold-40 home-second-section-title">
          {t("Solution for 2D and 3D capture of a limb")}
        </h2>
        <div className="home-second-section-content">
          <p className="bold-24">
            {t(
              "The challenge for UVASCLab is to meet your expectations by offering you innovative tools for monitoring vascular pathologies such as lymphoedema"
            )}
            .
          </p>
          <p className="light-24">
            {t(
              "Our volumetry solution speeds up volume measurement, makes it more comfortable and improves the follow-up of your patients living with lymphoedema"
            )}
            .
          </p>
          <p className="bold-30">
            {t(
              "This application is easily installed on an iPad tablet and uses a 3D sensor"
            )}
            . {t("Your measurements are stored in a secure web application")}.
          </p>
        </div>
      </div>
    </section>
  )
}

export default HomeSecondSection
