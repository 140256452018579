import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import Carousel from "components/Carousel"
import ArrowRight from "images/arrow-right.inline.svg"
import Step1 from "images/how-work-1.inline.svg"
import Step2 from "images/how-work-2.inline.svg"
import Step3 from "images/how-work-3.inline.svg"

const HomeFifthSection = () => {
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState(1)

  return (
    <section>
      <div className="home-fifth-section-1">
        <div className="container">
          <h2 className="bold-60 home-fifth-section-title">
            {t("How it works?")}
          </h2>
          <div className="home-fifth-section-1-content">
            <Step1
              id="step-1"
              className={currentStep === 1 ? "step active" : "step"}
              width="122"
              onClick={() => setCurrentStep(1)}
            />
            <ArrowRight className="arrow" height="15" />
            <Step2
              id="step-2"
              className={currentStep === 2 ? "step active" : "step"}
              width="122"
              onClick={() => setCurrentStep(2)}
            />
            <ArrowRight className="arrow" height="15" />
            <Step3
              id="step-3"
              className={currentStep === 3 ? "step active" : "step"}
              width="122"
              onClick={() => setCurrentStep(3)}
            />
          </div>
        </div>
      </div>
      <div className="home-fifth-section-2">
        <div className="container">
          <Carousel step={currentStep} setStep={setCurrentStep}>
            <div className="how-works-step">
              <Step1 className="step" width="168" />
              <div className="how-works-text">
                <p className="bold-30">1 - {t("Don't waste time!")}</p>
                <p className="light-24">
                  {t(
                    "The application is designed to take a simple and reliable measurement of limb volume in seconds"
                  )}
                  . {t("The result is obtained thanks to our algorithms")}.
                </p>
              </div>
            </div>
            <div className="how-works-step">
              <Step2 className="step" width="168" />
              <div className="how-works-text">
                <p className="bold-30">2 - {t("Don't waste time!")}</p>
                <p className="light-24">
                  {t(
                    "The application is designed to take a simple and reliable measurement of limb volume in seconds"
                  )}
                  . {t("The result is obtained thanks to our algorithms")}.
                </p>
              </div>
            </div>
            <div className="how-works-step">
              <Step3 className="step" width="168" />
              <div className="how-works-text">
                <p className="bold-30">3- {t("Don't waste time!")}</p>
                <p className="light-24">
                  {t(
                    "The application is designed to take a simple and reliable measurement of limb volume in seconds"
                  )}
                  . {t("The result is obtained thanks to our algorithms")}.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  )
}

export default HomeFifthSection
